<template>
    <div class="w-100">
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center gap-3">
                <h5 class="mb-0 whitespace-nowrap">{{ $t('components.app.pay.index.usePoints') }}</h5>
                <b-form-select 
                    :value="pointsStore.selectedPointAccount ? pointsStore.selectedPointAccount.id : ''"
                    :disabled="!pointsStore.usePoints"
                    :options="availablePointAccountsForSelect()"
                    size="sm"
                    @change="selectedPointAccountUpdated">
                </b-form-select>
            </div>
            <div>
                <b-form-checkbox
                    size="md"
                    switch
                    :disabled="payStore.paymentIsStarted"
                    v-model="pointsStore.usePoints"
                    @change="usePointsUpdated">
                </b-form-checkbox>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import Vue from 'vue'
import { usePayPointsStore } from '@/store/modules/pay/points'
import { usePayPaymentMethodStore } from '@/store/modules/pay/paymentMethod'
import { PaymentTokenPaymentType } from '@/graphql'
import { usePayPriceStore } from '@/store/modules/pay/price'
import { usePayStore } from '@/store/modules/pay/pay'
import { i18n } from '@/locales/setupI18n'

export default Vue.extend({
    setup() {
        const pointsStore = usePayPointsStore()
        const paymentMethodStore = usePayPaymentMethodStore()
        const payStore = usePayStore()
        const priceStore = usePayPriceStore()

        const usePointsUpdated = async (usePoints: boolean): Promise<void> => {
            await pointsStore.toggleUseRestPoint()
            // Discounts can not be combined with points if removeDiscountToDoPayment = true
            // If usePoints is changed, update whether discounts can be used
            const useDiscounts = !(usePoints && pointsStore.selectedPointAccount?.removeDiscountToDoPayment)
            priceStore.setUseDiscounts(useDiscounts)
            pointsStore.setUsePointsInsteadOfDiscount(!useDiscounts)
            
            const paymentType = (usePoints && pointsStore.selectedPointAccount?.enoughPointsForPayment)
                ? PaymentTokenPaymentType.Points
                : PaymentTokenPaymentType.Stripe

            await paymentMethodStore.setPaymentType(paymentType, 'usePointsUpdated')
        }

        const selectedPointAccountUpdated = async (value: string): Promise<void> => {
            const selectedPointAccount = pointsStore.availablePointAccounts.find(x => x.id === value)
            if (selectedPointAccount) {
                pointsStore.setPointAccount(selectedPointAccount)
                
                const paymentType = (pointsStore.usePoints && selectedPointAccount.enoughPointsForPayment)
                ? PaymentTokenPaymentType.Points
                : PaymentTokenPaymentType.Stripe
                
                paymentMethodStore.setPaymentType(paymentType, 'selectedPointAccountUpdated')
                await usePointsUpdated(!!pointsStore.usePoints)
            }
        }

        const availablePointAccountsForSelect = (): { value: string, text: string, disabled: boolean }[]  => {
            return pointsStore.availablePointAccounts.map((x) => ({
              value: x.id,
              text: `${x.userGroup ? x.userGroup.name : i18n.t('components.app.pay.index.privatePointAccount')} (${x.pointsAmount} ${i18n.t('components.app.pay.index.points')})`,
              disabled: x.pointsAmount < 1
            }))
        }

        return {
            pointsStore,
            paymentMethodStore,
            payStore,
            priceStore,
            usePointsUpdated,
            selectedPointAccountUpdated,
            availablePointAccountsForSelect,
        }
    }
})
</script>
<style lang="scss" scoped>
</style>