<template lang="pug">
div
	b-modal(
		id="userProductSubscriptionModal"
		v-model="userProductSubscriptionModalVisible"
		centered
		hide-footer
		hide-header
		:no-close-on-backdrop="true"
		:no-close-on-esc="true"
		size="md")
		div(v-if="subscriptionProduct && subscriptionProduct.userProductSubscription")
			b-row.justify-content-center.align-items-center
				b-col(auto)
					h2.m-0 {{ $t('components.app.subscriptionProduct.exstingSubscription.text') }} {{ subscriptionProduct.userProductSubscription.company.name }}

				b-col.text-right(cols="auto")
					b-link.text-primary(@click="userProductSubscriptionModalVisible = false") {{ $t('components.app.subscriptionProduct.closeModalText') }}
			
			b-card.bg-light.shadow-none.card-no-border.card-small-padding.m-0.mt-3(v-if="subscriptionProduct.userProductSubscription.status === 'PendingStatus' || subscriptionProduct.userProductSubscription.latestPayment?.status === 'Failed'")
				h3.mt-3 {{ $t('components.app.subscriptionProduct.createSubscription.paymentFailed.title') }}
				p.text-muted.mb-0 {{ $t('components.app.subscriptionProduct.createSubscription.paymentFailed.text') }}

				b-button.mt-3(
					variant="primary"
					:disabled="!subscriptionProduct.userProductSubscription.latestPayment"
					@click="openUrl(subscriptionProduct.userProductSubscription.latestPayment.invoiceUrl)"
					size="md"
					block) 
					span(v-if="!subscriptionProduct.userProductSubscription.latestPayment")
						span {{ $t('components.app.subscriptionProduct.createSubscription.paymentFailed.buttonLoading') }}
						b-spinner.ml-3.button-loading(small type="grow")
					span(v-else) {{ $t('components.app.subscriptionProduct.createSubscription.paymentFailed.button') }}


			b-card.bg-light.shadow-none.card-no-border.card-small-padding.m-0.mt-3(v-else-if="subscriptionJustCreated")
				h3.mt-3 {{ $t('components.app.subscriptionProduct.createSubscription.done.title') }}
				p.text-muted.mb-0 {{ $t('components.app.subscriptionProduct.createSubscription.done.text') }}

			b-card.bg-light.shadow-none.card-no-border.mt-4.card-small-padding
				.information-lines
					.line
						small.text-muted.m-0 {{ $t('components.app.subscriptionProduct.exstingSubscription.membershipName') }}:
						p.m-0 {{subscriptionProduct.name}}
					.line
						small.text-muted.m-0 {{ $t('components.app.subscriptionProduct.exstingSubscription.createdAt') }}:
						p.m-0 {{ subscriptionProduct.userProductSubscription.createdAt | moment('D. MMM YYYY') }}
					.line
						small.text-muted.m-0 {{ $t('components.app.subscriptionProduct.exstingSubscription.status') }}:
						UserProductSubscriptionStatus(:status="getUserProductSubscriptionStatus()")

			b-card.bg-light.shadow-none.card-no-border.card-small-padding.m-0(v-if="subscriptionProduct.userProductSubscription.status === 'ToActive' && !subscriptionProduct.activeBeforeFirstPayment")
				p.mb-2 {{ $t('components.app.subscriptionProduct.exstingSubscription.toActive.title') }}
				small.text-muted {{ $t('components.app.subscriptionProduct.exstingSubscription.toActive.text') }} {{ subscriptionProduct.startDateForFirstPaymentUser | moment('DD/MM/YYYY') }}

			div(v-else-if="subscriptionProduct.userProductSubscription.cancelDate")
				b-card.mb-0.bg-light.shadow-none.card-no-border.card-small-padding
					h4.m-0 {{ $t('components.app.subscriptionProduct.exstingSubscription.cancelAt') }}: {{ subscriptionProduct.userProductSubscription.cancelDate | moment('D. MMMM YYYY') }}

				b-button.mt-3(
					v-if="subscriptionProduct.userProductSubscription.status === 'ToCancele'"
					variant="primary"
					@click="reactivateSubscription()"
					size="md"
					block) {{ $t('components.app.subscriptionProduct.exstingSubscription.reactivateMembership') }}

			b-overlay(
				v-else-if="subscriptionProduct.userProductSubscription.userCanCancel && !subscriptionJustCreated"
				spinner-variant="primary"
				variant="transperant"
				:show="cancelProductSubscription.isLoading")

				b-button(
					variant="danger"
					@click="cancelSubscription()"
					size="md"
					block) {{ $t('components.app.subscriptionProduct.exstingSubscription.cancelSubscriptionButton') }}


	b-modal.bottom-modal(
		v-model="createUserProductSubscriptionModalVisible"
		:backdrop="true"
		hide-header
		hide-footer
		modal-class="bottom-modal"
		body-class="p-0")
		.buttom-sidebar-close-wrapper.cursor-pointer(@click="resetSubscriptionModal()")
			.fe.fe-arrow-left.text-muted.mr-2
			small.text-muted {{ $t('common.cancel') }}

		div.bottom-modal-content.p-2(v-if="subscriptionProduct && !stripeStore.showStripeWebPaymentElement")
			.px-3.pb-3
				b-card.bg-light.shadow-none.card-no-border.mt-4.card-small-padding(v-if="subscriptionProduct")
					p.text-muted.m-0
						span {{ $t('components.app.subscriptionProduct.createSubscription.buyText.one') }} <span class="text-dark"> "{{ subscriptionProduct.name }}"</span> {{ $t('components.app.subscriptionProduct.createSubscription.buyText.two') }} <span class="text-dark">{{ currencyFormatter(subscriptionProduct.product.price, locationStore.userLocation.currency) }}</span>.
						span(v-if="subscriptionProduct.signUpFeeProduct.price")  {{ $t('components.app.subscriptionProduct.createSubscription.buyText.four') }} <span class="text-dark">{{ currencyFormatter(subscriptionProduct.signUpFeeProduct.price, locationStore.userLocation.currency) }}</span>.

					b-card.mt-4.mb-0(v-if="doNeedToAcceptSubscriptionAgreements")
						b-form-checkbox.text-muted(
							size="md"
							v-model='acceptSubscriptionAgreement') {{ $t('components.app.subscriptionProduct.createSubscription.acceptSubscriptionAgreement.text') }}

						b-link.mt-2.d-block.mb-0(
							v-for="agreement in agreementsForCreateUserProductSubscription"
							:key="agreement.id"
							:href="agreement.documentUrl"
							target="_blank") {{ $t('components.app.subscriptionProduct.createSubscription.acceptSubscriptionAgreement.readMoreLink') }}

				b-card.bg-light.shadow-none.card-no-border.mt-4.card-small-padding.mb-0(v-if="locationUserDefault")
					p.text-muted.m-0 {{ $t('components.app.subscriptionProduct.createSubscription.createdAtCompany.one') }} <b class="text-body">{{ locationUserDefault.name }}</b>. {{ $t('components.app.subscriptionProduct.createSubscription.createdAtCompany.two') }}
					br
					p.text-muted.m-0(v-html="$t('components.app.subscriptionProduct.createSubscription.activateMembershipInformation')")

			b-card.card-small-padding.m-0.mt-4.px-3.pb-3(
				v-if="doNeedToAcceptSubscriptionAgreements && !acceptSubscriptionAgreement"
				bg-variant="warning"
				text-variant="white")
				p.mb-0 {{ $t('components.app.subscriptionProduct.createSubscription.youNeedToAcceptAgreements') }}

			div(v-else)
				pay

			.safe-area-inset-bottom-0

		div.bottom-modal-content.p-2(v-else)
			StripePaymentElement(@back-to-overview="resetSubscriptionModal()")


	ConfirmModal(
		ref="confirm-modal"
		:title="modalInfo.title"
		:message="modalInfo.message"
		:okVariant="modalInfo.okVariant")

</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import {
	UserProductSubscriptionCancel,
	UserProductSubscriptionCancelMutationVariables,
	GetLocationUserDefault,
	GetLocationUserDefaultQuery,
	GetSubscriptionProductForUser,
	GetSubscriptionProductForUserQueryVariables,
	GetSubscriptionProductForUserQuery,
	GetAgreementsForCreateUserProductSubscription,
	GetAgreementsForCreateUserProductSubscriptionQuery,
	UserAcceptAgreements,
	UserAcceptAgreementsMutationVariables,
	ProductType,
	UserProductSubscriptionStatus as UserProductSubscriptionStatusEnum,
	UserProductSubscriptionReactivate,
	UserProductSubscriptionReactivateMutationVariables,
	PaymentTokenFaildReason,
	SubscribeInvoicePaid,
	SubscribeInvoicePaidSubscription,
} from "@/graphql";
import Pay from "@/components/app/pay/index.vue";
import { SmartMutation } from "@/utils/smartMutation";
import ChangeUserDefaultLocation from "@/components/app/changeUserDefaultLocation.vue";
import UserProductSubscriptionStatus from "@/components/admin/enumTranformers/userProductSubscriptionStatus.vue";
import AddStripeSource from "@/components/app/addStripeSource.vue";
import { usePaySubscriptionProductGiftCardStore } from "@/store/modules/pay/subscriptionProductGiftCard";
import { usePayStripeStore } from "@/store/modules/pay/stripe";
import { DoneFunction, usePayStore } from "@/store/modules/pay/pay";
import StripePaymentElement from "@/components/app/pay/stripePaymentElement.vue";
import { usePayPaymentTokenStore } from "@/store/modules/pay/paymentToken";
import { currencyFormatter } from "@/utils/currencyFormatter";
import { useLocationStore } from "@/store/modules/location";

@Component({
	components: {
		Pay,
		UserProductSubscriptionStatus,
		ChangeUserDefaultLocation,
		AddStripeSource,
		StripePaymentElement,
	},
	methods: {
		currencyFormatter,
	},
	apollo: {
		locationUserDefault: {
			query: GetLocationUserDefault,
		},
		agreementsForCreateUserProductSubscription: {
			query: GetAgreementsForCreateUserProductSubscription,
			update(data: GetAgreementsForCreateUserProductSubscriptionQuery) {
				if (!data.agreementsForCreateUserProductSubscription) return [];

				data.agreementsForCreateUserProductSubscription.forEach((x) => {
					this.$set(this.acceptedAgreements, x.id, false);
				});

				return data.agreementsForCreateUserProductSubscription;
			},
		},
		subscriptionProduct: {
			query: GetSubscriptionProductForUser,
			variables() {
				return {
					id: this.subscriptionProductId,
				};
			},
			skip() {
				return !this.subscriptionProductId;
			},
			update(data) {
				this.subscriptionProduct = data.subscriptionProduct;
				return data.subscriptionProduct;
			},
			subscribeToMore: {
				document: SubscribeInvoicePaid,
				variables() {
					return {
						subscriptionId:
							this.subscriptionProduct?.userProductSubscription?.id ?? "",
					};
				},
				skip() {
					return !this.subscriptionProduct?.userProductSubscription?.id;
				},
				updateQuery: (
					previousResult: GetSubscriptionProductForUserQuery,
					{
						subscriptionData,
					}: { subscriptionData: { data: SubscribeInvoicePaidSubscription } }
				) => {
					if (!subscriptionData.data) return previousResult;
				},
			},
		},
	},
})
export default class CompanyUsersUserView extends Vue {
	paySubscriptionProductGiftCardStore =
		usePaySubscriptionProductGiftCardStore();

	subscriptionProductId: string | null = null;
	subscriptionProduct:
		| GetSubscriptionProductForUserQuery["subscriptionProduct"]
		| null = null;

	userProductSubscriptionModalVisible = false;
	createUserProductSubscriptionModalVisible = false;
	subscriptionJustCreated = false;

	acceptedAgreements: { [key: string]: boolean } = {};
	agreementsForCreateUserProductSubscription = [];
	acceptSubscriptionAgreement = false;
	readyForPay = false;

	locationUserDefault: GetLocationUserDefaultQuery["locationUserDefault"] =
		null;

	stripeStore = usePayStripeStore();
	payStore = usePayStore();
	paymentTokenStore = usePayPaymentTokenStore();
	locationStore = useLocationStore();

	modalInfo = {
		okVariant: "",
		message: "",
		title: ""
	}

	get doNeedToAcceptSubscriptionAgreements() {
		if (this.agreementsForCreateUserProductSubscription.length) return true;
		return false;
	}

	async created() {
		this.cancelProductSubscription.componentThis = this;
		this.userAcceptAgreements.componentThis = this;
		this.userProductSubscriptionReactivate.componentThis = this;
	}

	userAcceptAgreements = new SmartMutation({
		componentThis: null,
		mutation: UserAcceptAgreements,
		variables: (): UserAcceptAgreementsMutationVariables => {
			return {
				declinedAgreements: [],
				acceptedAgreements: [],
			};
		},
	});

	async acceptAgreementsForSignUp() {
		const keys = Object.keys(this.acceptedAgreements);

		this.userAcceptAgreements.variables = {
			declinedAgreements: [],
			acceptedAgreements: keys.map((x) => x),
		};
		await this.userAcceptAgreements.mutate();
	}

	async querySubscriptionProduct() {
		const { data } = await this.$apollo.query<
			GetSubscriptionProductForUserQuery,
			GetSubscriptionProductForUserQueryVariables
		>({
			query: GetSubscriptionProductForUser,
			variables: { id: this.subscriptionProductId! },
			fetchPolicy: "network-only",
		});

		this.subscriptionProduct = data.subscriptionProduct;
	}

	async show(subscriptionProductId: string) {
		if (!subscriptionProductId)
			throw new Error(
				this.$t("common.staticTemp.points.errorMessagePointId") as string
			);
		this.subscriptionProductId = subscriptionProductId;

		await this.querySubscriptionProduct();

		if (this.subscriptionProduct?.userProductSubscription) {
			const status = this.subscriptionProduct.userProductSubscription.status;
			if (status === UserProductSubscriptionStatusEnum.Canceled)
				this.createUserProductSubscriptionModalVisible = true;
			else if (status === UserProductSubscriptionStatusEnum.Unpaid)
				this.createUserProductSubscriptionModalVisible = true;
			else this.userProductSubscriptionModalVisible = true;
		} else this.createUserProductSubscriptionModalVisible = true;

		if (this.createUserProductSubscriptionModalVisible) {
			await this.initSubscriptionProductPayment();
		}
	}

	@Watch("payStore.paymentFailed")
	async paymentFailed() {
		if (this.payStore.paymentFailed) {
			this.$bvToast.toast(
				this.$t(
					"components.app.subscriptionProduct.createSubscription.paymentFailed.title"
				) as string,
				{
					title: this.$t(
						"components.app.subscriptionProduct.createSubscription.paymentFailed.shortTitle"
					) as string,
					variant: "danger",
				}
			);

			this.createUserProductSubscriptionModalVisible = false;
			await this.querySubscriptionProduct();
			this.userProductSubscriptionModalVisible = true;

			setTimeout(() => {
				this.querySubscriptionProduct();
			}, 5000);
		}
	}

	async initSubscriptionProductPayment() {
		await this.payStore.initPayment({
			source: "initSubscriptionProductPayment",
			doneFunction: this.doneFunction,
			purchaseData: {
				productType: ProductType.SubscriptionProduct,
				subscriptionProductId: this.subscriptionProductId!,
			},
		});
	}

	doneFunction: DoneFunction = async () => {
		this.doneFunctionDone();
	};

	async doneFunctionDone() {
		await this.acceptAgreementsForSignUp();
		await this.querySubscriptionProduct();
		this.createUserProductSubscriptionModalVisible = false;
		this.userProductSubscriptionModalVisible = true;
		this.subscriptionJustCreated = true;

		setTimeout(() => {
			this.querySubscriptionProduct();
		}, 5000);
	}

	cancelProductSubscription = new SmartMutation({
		componentThis: null,
		doneToast: {
			i18nString:
				"components.app.subscriptionProduct.cancelProductSubscription.successToast",
		},
		mutation: UserProductSubscriptionCancel,
		variables: (): UserProductSubscriptionCancelMutationVariables => {
			return {
				userProductSubscription: "",
			};
		},
	});

	async cancelSubscription() {
		// @ts-ignore
		const statusBeforeCancellation =
			this.subscriptionProduct?.userProductSubscription?.status;

		this.modalInfo.okVariant = 'btn-danger'
		this.modalInfo.title = this.$t("components.app.subscriptionProduct.cancelProductSubscription.sureModal.title") as string
		this.modalInfo.message = this.$t("components.app.subscriptionProduct.cancelProductSubscription.sureModal.text") as string

		//@ts-ignore
		const userAccept = await this.$refs['confirm-modal'].open()
		if (!userAccept) return

		if (userAccept) {
			await this.cancelProductSubscription.mutate({
				userProductSubscription:
					this.subscriptionProduct!.userProductSubscription!.id,
			});
			await this.querySubscriptionProduct();
			if (statusBeforeCancellation === "PendingStatus") {
				this.userProductSubscriptionModalVisible = false;
			}
		}
	}

	userProductSubscriptionReactivate = new SmartMutation({
		componentThis: null,
		doneToast: {
			i18nString:
				"things.userProductSubscription.reactivateSubscription.successToast",
		},
		mutation: UserProductSubscriptionReactivate,
		variables: (): UserProductSubscriptionReactivateMutationVariables => {
			return {
				userProductSubscription: "",
			};
		},
	});

	async reactivateSubscription() {

		this.modalInfo.okVariant = 'success'
		this.modalInfo.title = this.$t("components.app.subscriptionProduct.reactivateProductSubscription.sureModal.title") as string
		this.modalInfo.message = this.$t("components.app.subscriptionProduct.reactivateProductSubscription.sureModal.text") as string

		//@ts-ignore
		const userAccept = await this.$refs['confirm-modal'].open()
		if (!userAccept) return

		if (userAccept) {
			await this.userProductSubscriptionReactivate.mutate({
				userProductSubscription:
					this.subscriptionProduct!.userProductSubscription!.id,
			});
			this.querySubscriptionProduct();
		}
	}

	openUrl(openUrl: string) {
		const win = window.open(openUrl, "_blank");
		win!.focus();
	}

	@Watch("stripeStore.resetSubscriptionModalTrigger")
	async resetSubscriptionModal(): Promise<void> {
		this.stripeStore.showStripeWebPaymentElement = false;
		this.createUserProductSubscriptionModalVisible = false;
		await this.paymentTokenStore.resetPaymentToken(
			PaymentTokenFaildReason.Canceled,
			false
		);
		this.payStore.startPaymentIsInitialized = false;
		if (this.payStore.paymentIsStarted) {
			await this.show(this.subscriptionProductId!);
		}
		this.payStore.paymentIsStarted = false;
	}

	getUserProductSubscriptionStatus() {
		if (this.subscriptionProduct?.userProductSubscription?.latestPayment?.status === 'Failed') {
			return 'Unpaid'
		} else {
			return this.subscriptionProduct?.userProductSubscription?.status
		}
	}
}
</script>

<style lang="sass" scoped>

.information-lines
	.line
		display: flex
		justify-content: space-between
		border-bottom: 1px solid #E3EBF6
		margin-bottom: 10px
		padding-bottom: 10px

		&:nth-last-of-type(1)
			border-bottom: 0
			margin-bottom: 0
			padding-bottom: 0
</style>
