import { defineStore } from 'pinia'
import { usePayPaymentTokenStore } from './paymentToken'
import { usePayLoadingStore } from './loading'
//@ts-ignore
import { Capacitor } from '@capacitor/core'
import { usePayStore } from './pay'
import { PaymentFlowEventsEnum, PaymentSheetEventsEnum, Stripe } from '@capacitor-community/stripe'
import { GetStripeGetSetupIntent, GetStripeGetSetupIntentQuery, GetStripeGetSetupIntentQueryVariables, GetUserStripe, GetUserStripeQuery, GetUserStripeQueryVariables, PaymentTokenFaildReason, ProductType, StripePaymentMetodAdded, StripePaymentMetodAddedMutation, StripePaymentMetodAddedMutationVariables, StripeUserRemovePaymentMethod, StripeUserRemovePaymentMethodMutation, StripeUserRemovePaymentMethodMutationVariables } from '@/graphql'
import { i18n } from '@/locales/setupI18n'
import { apolloClient } from '@/plugins/apollo'
import VueApp from '@/main'

const ledapDisplayName = 'LeDap'

interface PayStripeState {
  stripeIsInitialized: boolean
  showStripeWebPaymentElement: boolean
  useStripeWeb: boolean
  resetSubscriptionModalTrigger: number
  savedStripeSources: GetUserStripeQuery['user']['stripeSources']
}

export const usePayStripeStore = defineStore({
  id: 'PayStripe',

  state: (): PayStripeState => ({
    stripeIsInitialized: false,
    showStripeWebPaymentElement: false,
    useStripeWeb: false,
    resetSubscriptionModalTrigger: 0,
    savedStripeSources: []
  }),

  getters: {

  },

  actions: {
    async initStripe() {
      const stripeKey = process.env.STRIPE_KEY ?? ''
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('initStripe', true, {
        stripeIsInitialized: this.stripeIsInitialized
      })

      this.stripeIsInitialized = false

      const platform = Capacitor.getPlatform()

      if (platform === 'web') {
        this.useStripeWeb = true
      }
      await Stripe.initialize({
        publishableKey: stripeKey
      })

      this.stripeIsInitialized = true

      payLoadingStore.set('initStripe', false)
    },

    async initStripePayment() {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('initStripePayment', true)
      const platform = Capacitor.getPlatform()
      if (platform === 'web') {
        this.showStripeWebPaymentElement = true
      } else {
        this.initAppPayment()
      }
      payLoadingStore.set('initStripePayment', false)
    },

    /**
     * Payment sheet - native
     */
    async initAppPayment() {
      const paymentTokenStore = usePayPaymentTokenStore()

      // NULL-CHECKING FOR REQUIRED PARAMETERS
      if (!paymentTokenStore.paymentToken) throw new Error(i18n.t('common.staticTemp.stripeTs.errorMessagePaymentToken') as string)
      if (!paymentTokenStore.paymentToken.stripeClientSecret) throw new Error(i18n.t('common.staticTemp.stripeTs.errorMessageClientSecret') as string) 
      if (!paymentTokenStore.paymentToken.user.stripeCustomerId) throw new Error(i18n.t('common.staticTemp.stripeTs.errorMessageCustomerId') as string) 
      if (!paymentTokenStore.paymentToken.user.stripeEphemeralKey) throw new Error(i18n.t('common.staticTemp.stripeTs.errorMessageEphemeralKey') as string) 

      const platform = Capacitor.getPlatform()

      await Stripe.createPaymentSheet({
        paymentIntentClientSecret: paymentTokenStore.paymentToken.stripeClientSecret,
        customerId: paymentTokenStore.paymentToken.user.stripeCustomerId,
        customerEphemeralKeySecret: paymentTokenStore.paymentToken.user.stripeEphemeralKey,
        merchantDisplayName: ledapDisplayName,
        returnURL: `${import.meta.env.VITE_PLATFORM_URL}/ext-payment-redirect`,
        enableApplePay: platform === 'ios',
        applePayMerchantId: platform === 'ios' ? 'merchant.ledap.app' : undefined,
        countryCode: paymentTokenStore.paymentToken.country,
        enableGooglePay: platform === 'android'
      })

      const { paymentResult } = await Stripe.presentPaymentSheet()

      if (paymentResult === PaymentSheetEventsEnum.Completed) {
        // In case the payment token subscriber fails - refetch the payment token
        paymentTokenStore.refetchPaymentToken()
      } else if (paymentResult === PaymentSheetEventsEnum.Canceled) {
        const payStore = usePayStore()
        if (payStore.purchaseData?.productType === ProductType.SubscriptionProduct) {
          this.resetSubscriptionModalTrigger++
        } else {
          await paymentTokenStore.resetPaymentToken(PaymentTokenFaildReason.Canceled, false)
          payStore.startPaymentIsInitialized = false
          payStore.paymentIsStarted = false
        }
      }
    },

    async loadStripeUser() {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('loadStripeUser', true)

      const { data } = await apolloClient.query<GetUserStripeQuery, GetUserStripeQueryVariables>({
        query: GetUserStripe,
        variables: { },
        fetchPolicy: 'network-only'
      })

      this.savedStripeSources = data.user.stripeSources
      payLoadingStore.set('loadStripeUser', false)
      return data.user.haveSavedStripeSource
    },

    async removeStripeSource(paymentMethodId: string) {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('removeStripeSource', true)

      const deletePaymentMethod = await apolloClient.mutate<StripeUserRemovePaymentMethodMutation, StripeUserRemovePaymentMethodMutationVariables>({
        mutation: StripeUserRemovePaymentMethod,
        variables: {
          paymentMethodId
        },
        errorPolicy: 'all'
      })

      if (deletePaymentMethod.errors) {
        VueApp.$bvToast.toast(deletePaymentMethod.errors[0].message, {
          title: i18n.t('common.error') as string,
          variant: 'danger'
        })
      }

      await this.loadStripeUser()

      payLoadingStore.set('removeStripeSource', false)
    },

    async createSavedSource(stripe?: any, card?: any) {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('createSavedSourceLoading', true)

      const stripeSetupIntent = await this.getSetupIntent()

      try {
        if (this.useStripeWeb) {
          await stripe.confirmCardSetup(stripeSetupIntent.stripeIntentClientSecret, {
            payment_method: {
              card,
            },
          })
        }
        else {
          await Stripe.createPaymentFlow({
            setupIntentClientSecret: stripeSetupIntent.stripeIntentClientSecret,
            customerEphemeralKeySecret: stripeSetupIntent.stripeEphemeralKey,
            customerId: stripeSetupIntent.stripeCustomerId,
            withZipCode: false,
            merchantDisplayName: ledapDisplayName
          })
          
          await Stripe.presentPaymentFlow()
          const confirmResult = await Stripe.confirmPaymentFlow();
  
          if (confirmResult.paymentResult === PaymentFlowEventsEnum.Completed) {
            // Happy path
          } 
        }

        await apolloClient.mutate<StripePaymentMetodAddedMutation, StripePaymentMetodAddedMutationVariables>({
          mutation: StripePaymentMetodAdded,
          variables: {}
        })

        await this.loadStripeUser()
      } catch (error) {
        console.log('createSavedSource - error', error)
      }

      payLoadingStore.set('createSavedSourceLoading', false)
    },

    async getSetupIntent() {
      const { data: { stripeGetSetupIntent } } = await apolloClient.query<GetStripeGetSetupIntentQuery, GetStripeGetSetupIntentQueryVariables>({
        query: GetStripeGetSetupIntent,
        variables: {},
        fetchPolicy: 'network-only'
      })

      return stripeGetSetupIntent
    },
  }
})
