<template lang="pug">
b-modal.bottom-modal(
	:backdrop="true"
	hide-header
	hide-footer
	modal-class="bottom-modal"
	v-model="buyPointsVisible"
	body-class="p-0")
	.buttom-sidebar-close-wrapper.cursor-pointer(
		v-if="buyPointsStep !== 1"
		@click="closeWindow()")
		.fe.fe-arrow-left.text-muted.mr-2
		small.text-muted {{ $t('components.app.buyPointsModal.cancel') }}


	b-overlay.bottom-modal-content(
		:show="$apollo.queries.pointProduct.loading"
		variant="white"
		spinner-variant="primary")

		div(v-if="pointProduct && !stripeStore.showStripeWebPaymentElement")
			div(v-if="buyPointsStep === 0")
				.px-3.pt-4.pb-3
					b-card.bg-light.shadow-none.card-no-border.card-small-padding
						p.text-muted.m-0
							span {{ $t('components.app.buyPointsModal.productInfo.buy') }} <b class="text-dark"> {{ pointProduct.pointsAmount }} {{ $t('components.app.buyPointsModal.productInfo.points') }} </b> {{ $t('components.app.buyPointsModal.productInfo.price') }} <b class="text-dark">{{ currencyFormatter(pointProduct.product.price, locationStore.userLocation.currency) }}</b>

					b-card.mb-0.bg-light.shadow-none.card-no-border.mt-4.card-small-padding(v-if="locationUserDefault")
						p.text-muted.m-0 {{ $t('components.app.buyPointsModal.willBeOnAccount') }}&nbsp;
							b.text-body(v-if="locationUserDefault.company.pointAccountUsingFranchise") {{ locationUserDefault.company.franchise.name }}&nbsp;
							b.text-body(v-else) {{ locationUserDefault.company.name }}&nbsp;
							span -&nbsp;
							ChangeUserDefaultLocation.d-inline-block(
								:title="$t('components.app.changeUserDefaultLocation.defaultTitle')"
								@locationChangeInit="locationChange()")

				Pay

				.safe-area-inset-bottom-0

			div.p-4(v-else-if="buyPointsStep === 1")
				.d-flex.flex-column.justify-content-center
					img(src="/static/images/success-icon.svg" height="200px")
					h3.mt-3.text-center {{ $t('components.app.buyPointsModal.done.title') }}
					p.text-center.text-muted {{ $t('components.app.buyPointsModal.done.text') }}

					b-button.mt-4(variant="primary" size="lg" block @click="finishCloseModal()") {{ $t('components.app.buyPointsModal.done.backToStart') }}

			div(v-else)
				h3 {{ $t('components.app.buyPointsModal.error.title') }}
				a.text-danger(@click="buyPointsVisible = false") {{ $t('components.app.buyPointsModal.error.cancel') }}
		
		div.bottom-modal-content.pt-2(v-if="stripeStore.showStripeWebPaymentElement")
			StripePaymentElement(@back-to-overview="backToOverview()")

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { GetPointProduct, GetPointProductQueryVariables, GetPointProductQuery, GetLocationUserDefault, GetLocationUserDefaultQuery, GetPointAmount, GetPointAmountQuery, ProductType, PaymentTokenFaildReason } from '@/graphql'
import Pay from '@/components/app/pay/index.vue'
import ChangeUserDefaultLocation from '@/components/app/changeUserDefaultLocation.vue'
import { saEvent } from '../../utils/saEvent'
import { usePayStripeStore } from '@/store/modules/pay/stripe'
import StripePaymentElement from './pay/stripePaymentElement.vue'
import { DoneFunction, usePayStore } from '@/store/modules/pay/pay'
import { usePayPaymentTokenStore } from '@/store/modules/pay/paymentToken'
import { currencyFormatter } from '@/utils/currencyFormatter'
import { useLocationStore } from '@/store/modules/location'

@Component({
	components: {
		Pay,
		ChangeUserDefaultLocation,
		StripePaymentElement
	},
	methods: {
		currencyFormatter
	},
	apollo: {
		locationUserDefault: {
			query: GetLocationUserDefault
		},
		pointProduct: {
			query: GetPointProduct,
			variables(): GetPointProductQueryVariables {
				return {
					id: this.pointProductId
				}
			},
			skip() { return this.pointProductId ? false : true }
		},
		pointAmount: {
			query: GetPointAmount,
			update(data: GetPointAmountQuery) {
				return data.me.pointAccount.pointsAmount
			}
		}
	}
})
export default class BuyPoints extends Vue {
	pointProductId: string | null = null
	pointAmount = 0
	pointProduct: GetPointProductQuery['pointProduct'] | null = null
	buyPointsStep = 0
	validCouponSlug = null
	stripeStore = usePayStripeStore()
	paymentTokenStore = usePayPaymentTokenStore()
	payStore = usePayStore()
	locationStore = useLocationStore()
	buyPointsVisible = false
	locationUserDefault: GetLocationUserDefaultQuery['locationUserDefault'] | null = null

	async closeWindow(): Promise<void> {
		this.buyPointsVisible = false
		this.buyPointsStep = 0
		await this.paymentTokenStore.resetPaymentToken(PaymentTokenFaildReason.Canceled, false)
	}

	async show(pointProductId: string) {
		if (!pointProductId) throw new Error(this.$t('common.staticTemp.points.errorMessagePointId') as string)
		this.pointProductId = pointProductId
		await saEvent('pointsBuy.init')
		this.buyPointsVisible = true
		this.initPayment()
	}

	finishCloseModal() {
		this.buyPointsStep = 0
		this.buyPointsVisible = false
	}

	async nextStep() {
		this.buyPointsStep++
		await saEvent('pointsBuy.done')
		this.$apollo.queries.pointAmount.refetch()
	}

	async initPayment() {
		const payStore = usePayStore()
		await payStore.initPayment({
			source: 'initPointsPayment',
			doneFunction: this.doneFunction,
			purchaseData: {
				productType: ProductType.PointProduct,
				pointProductId: this.pointProductId!
			}
		})
	}

	doneFunction: DoneFunction = async () => {
		this.nextStep()
	}

	locationChange() {
		this.buyPointsVisible = false
	}

	async backToOverview(): Promise<void> {
		this.stripeStore.showStripeWebPaymentElement = false
		await this.paymentTokenStore.resetPaymentToken(PaymentTokenFaildReason.Canceled, false)
		this.payStore.startPaymentIsInitialized = false
		this.payStore.paymentIsStarted = false
	}
}
</script>

<style lang="sass" scoped>

</style>
