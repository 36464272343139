<template lang="pug">
b-overlay(
	:show="payLoadingStore.loading && payStore.isInitialized"
	variant="white"
	spinner-variant="primary")
	template(#overlay)
		.d-flex.flex-column.justify-content-center.align-items-center
			b-spinner(variant="primary")
			p.mb-0.mt-3(v-if="payStore.loadingText") {{ payStore.loadingText }}
				span.loading-dots
					span .
					span .
					span .
	div
		.px-3.py-4
			.card-rows-wrapper.card-rows-wrapper--small-padding
				//- Amount Section
				.card-rows-row(v-if="payStore.purchaseData?.productType !== 'SubscriptionProduct'")
					div
						span(v-if="showServiceFee")
							small.text-muted {{ $t('components.app.pay.index.serviceFee.title') }}
							i(class="fe fe-help-circle ml-1 text-muted" id="service-fee-info" @click="serviceFeeInfoModalOpen = true")
						h4.mb-0 {{ $t('components.app.pay.index.totalAmount') }}
						small.text-muted {{ $t('components.app.pay.index.amount.includesVat') }}
					div
						div(v-if="showServiceFee" style="text-align: right;")
							h5.m-0.text-muted(v-if="payStore.isInitialized") {{ formatPrice(payPriceStore.priceData.platformFee) }}
						hr.mt-1.mb-2(v-if="showServiceFee")
						div.d-flex.align-items-center
							b-badge(
								v-if="payPriceStore.discountPercentage"
								size="sm"
								variant="success"
								style="line-height: normal") -{{ payPriceStore.discountPercentage }}%
							h4.m-0.ml-2(v-if="payStore.isInitialized")
								span(v-if="payPriceStore.isUsingDiscounts" style="text-decoration: line-through") {{ payPriceStore.priceData.amountWithoutCoupon }}
								span &nbsp;{{ formatPrice(payPriceStore.priceData.amountToPay ) }}
							b-skeleton(v-else width="70px" height="19px")
				
				//- Discount Code Section
				.card-rows-row(v-if="payCouponStore.canUseCoupon")
					div
						h4.mb-0 {{ $t('components.app.pay.index.discountCode') }}
					div.small
						PayCoupon
				
				//- Subscription Product Gift Card Section - CAMPAIGN CODE
				.card-rows-row(v-if="paySubscriptionProductGiftCardStore.showSubscriptonProductGiftCardOption")
					div
						h5.mb-0 {{ $t('components.app.pay.index.subscriptionProductGiftCard') }}
					div
						SubscriptionProductGiftCard
				
				//- Use Points Section
				.card-rows-row(v-if="paymentMethodStore.isPointsAllowedPaymentMethod && payPointsStore.selectedPointAccount")
					PayWithPoints
			
		PayButton
	
	ServiceFeeInfoModal(:open="serviceFeeInfoModalOpen" @close="serviceFeeInfoModalOpen = false")

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

import PayCoupon from './coupon.vue'
import SubscriptionProductGiftCard from './subscriptionProductGiftCard.vue'
import { usePayLoadingStore } from '@/store/modules/pay/loading'
import { usePayCouponStore } from '@/store/modules/pay/coupon'
import { usePayPriceStore } from '@/store/modules/pay/price'
import { usePaySubscriptionProductGiftCardStore } from '@/store/modules/pay/subscriptionProductGiftCard'
import { getCurrentBrowser, Browser } from '@/utils/getCurrentBrowser'
import { usePayPaymentMethodStore } from '@/store/modules/pay/paymentMethod'
import { usePayPointsStore } from '@/store/modules/pay/points'
import PayWithPoints from './payWithPoints.vue'
import PayButton from './payButton.vue'
import { usePayStore } from '@/store/modules/pay/pay'
import { usePayStripeStore } from '@/store/modules/pay/stripe'
import { currencyFormatter } from '@/utils/currencyFormatter';
import { useLocationStore } from '@/store/modules/location'
import ServiceFeeInfoModal from './serviceFeeInfoModal.vue'

@Component({
	components: {
		PayCoupon,
		SubscriptionProductGiftCard,
		PayWithPoints,
		PayButton,
		ServiceFeeInfoModal
	},
	apollo: {

	},
	data() {
		return {
			safari: Browser.Safari
		}
	}
})
export default class CompanyUsersUserView extends Vue {
	stripeStore = usePayStripeStore()
	paymentMethodStore = usePayPaymentMethodStore()
	payPointsStore = usePayPointsStore()
	payStore = usePayStore()
	payPriceStore = usePayPriceStore()
	payLoadingStore = usePayLoadingStore()
	payCouponStore = usePayCouponStore()
	paySubscriptionProductGiftCardStore = usePaySubscriptionProductGiftCardStore()
	locationStore = useLocationStore()

	browser: Browser | null = null

	serviceFeeInfoModalOpen: boolean = false

	created() {
		this.browser = getCurrentBrowser(window.navigator.userAgent)
		window.addEventListener('beforeunload', async function (event) {
			event.preventDefault()	
			const payStore = usePayStore()		
			payStore.browserUnload()
		})
	}

	formatPrice(value: number) {
		if (this.locationStore.userLocation?.currency) {
			return currencyFormatter(
				value,
				this.locationStore.userLocation?.currency
			);
		} else {
			return value;
		}
	}

	get showServiceFee() {
		if (!this.payPriceStore.priceData?.platformFee) {
			return false
		}

		const selectedPaymentType = this.paymentMethodStore.selectedPaymentType

		return (selectedPaymentType !== 'Points' && selectedPaymentType !== 'Free')
	}
}
</script>
<style lang="sass">
</style>
