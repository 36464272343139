import { PaymentTokenPaymentType, PaymentType, PaymentUserAllowedPaymentTypes, PaymentUserAllowedPaymentTypesQuery, PaymentUserAllowedPaymentTypesQueryVariables } from "@/graphql";
import { defineStore } from "pinia";
import { usePayPriceStore } from "./price";
import { i18n } from "@/locales/setupI18n";
import { usePayLoadingStore } from "./loading";
import { apolloClient } from "@/plugins/apollo";
import { usePayPointsStore } from "./points";
import { usePayStore } from "./pay";

interface PaymentMethodState {
    selectedPaymentType: PaymentTokenPaymentType | null
    allowedPaymentTypes: PaymentType[],
}

export const usePayPaymentMethodStore = defineStore({
    id: 'PayPaymentMethod',
    state: (): PaymentMethodState => ({
        selectedPaymentType: null,
        allowedPaymentTypes: [],
    }),
    getters: {
        isPointsAllowedPaymentMethod: (state): boolean => {
            return state.allowedPaymentTypes.some(x => x.paymentType === PaymentTokenPaymentType.Points)
        },
    },
    actions: {  
        async setPaymentType(paymentType: PaymentTokenPaymentType, source: string): Promise<void> {
            const payLoadingStore = usePayLoadingStore()
            const payStore = usePayStore()

            const prevPaymentType = this.selectedPaymentType
            this.selectedPaymentType = paymentType
            const paymentTypeChanged = prevPaymentType !== paymentType

            payLoadingStore.set(`setPaymentType`, true, {
                source,
                prevPaymentType,
                newPaymentType: paymentType,
                paymentTypeChanged,
            })

            await payStore.initStartPayment('setPaymentType')

            payLoadingStore.set(`setPaymentType`, false, {
                paymentTypeChanged
            })


        },
        async getAllowedPaymentTypes(source: string): Promise<void> {
            const payPriceStore = usePayPriceStore()
            const payLoadingStore = usePayLoadingStore()
            const pointsStore = usePayPointsStore()

            if (!payPriceStore.priceData) {
                throw new Error(i18n.t('common.staticTemp.payMethod.errorMessagePriceData') as string) 
            }

            payLoadingStore.set('getAllowedPaymentTypes', true, {
                source,
            })

            const { data } = await apolloClient.query<PaymentUserAllowedPaymentTypesQuery, PaymentUserAllowedPaymentTypesQueryVariables>({
                query: PaymentUserAllowedPaymentTypes,
                variables: {
                  paymentPriceToken: payPriceStore.priceData.paymentPriceToken         
                }
            })
        
            this.allowedPaymentTypes = data.paymentUserAllowedPaymentTypes.paymentTypes
            pointsStore.initPoints(data.paymentUserAllowedPaymentTypes.pointAccounts)
            this.setDefaultPaymentType('getAllowedPaymentTypes')

            payLoadingStore.set('getAllowedPaymentTypes', false, {
                source,
                allowedPaymentTypes: this.allowedPaymentTypes
            })
        },


        // Default settings for payment method
        setDefaultPaymentType(source: string): void {
            const payLoadingStore = usePayLoadingStore()
            const pointsStore = usePayPointsStore()
            const pointAccount = pointsStore.selectedPointAccount

            payLoadingStore.set('initSetPaymentType', true, {
                source
            })

            if (this.allowedPaymentTypes.length > 1) {
                let paymentType = null
                //  - If points are allowed and there is a point account
                //  - and the points can be combined with other discounts or there are no other discounts
                //  > default to points
                const defaultToPoints = pointAccount && pointAccount.enoughPointsForPayment && !pointAccount.removeDiscountToDoPayment && pointsStore.usePoints !== false
                if (defaultToPoints) {
                    paymentType = PaymentTokenPaymentType.Points
                // Else, default to Stripe Payment
                } else {
                    paymentType = PaymentTokenPaymentType.Stripe
                }
                const allowed = this.allowedPaymentTypes.find((x) => x.paymentType === paymentType)
                if (allowed) {
                    this.setPaymentType(paymentType, 'setDefaultPaymentType')
                }
            } else {
                // If there is only one allowed payment method, default to that payment method
                this.setPaymentType(this.allowedPaymentTypes[0].paymentType, 'setDefaultPaymentType')
            }

            payLoadingStore.set('initSetPaymentType', false, {
                source
            })
        },
        resetPaymentTypes(): void {
            this.allowedPaymentTypes = []
        },
        reset(): void {
            this.selectedPaymentType = null
            this.allowedPaymentTypes = []
        }
    }
})