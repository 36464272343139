<template lang="pug">
.app-layout
	app-navigation
	.main
		transition(
			enter-active-class="animated fadeIn"
			leave-active-class="animated fadeOut"
			mode='out-in')
			router-view(appear style="animation-duration: 0.2s")
</template>

<script lang="ts">
import AppNavigation from '@/components/app/navigation.vue'
import { Component, Vue } from 'vue-property-decorator'
import { } from '@/graphql'
import { useShopStore } from '@/store/modules/ecom'
import { usePayStore } from '@/store/modules/pay/pay'

@Component({
	components: {
		AppNavigation
	},
	apollo: {

	}
})
export default class CompanyUsersUserView extends Vue {

	created() {
		const payStore = usePayStore()
		payStore.loadPayment()

		const shopStore = useShopStore()
		shopStore.init()
	}

}
</script>

<style scoped lang="sass">

</style>
