import { PaymentCalculatePrice, PaymentCalculatePriceInput, PaymentCalculatePriceQuery, PaymentCalculatePriceQueryVariables, ProductType } from '@/graphql'
import { apolloClient } from '@/plugins/apollo'
import { defineStore } from 'pinia'
import { usePayCouponStore } from './coupon'
import { usePayLoadingStore } from './loading'
import { usePaySubscriptionProductGiftCardStore } from './subscriptionProductGiftCard'
import { usePayUserProductSubscriptionPaymentStore } from './userProductSubscriptionPayment'
import { i18n } from '@/locales/setupI18n'
import { usePayPaymentMethodStore } from './paymentMethod'
import { usePayPointsStore } from './points'
import { usePayStore } from './pay'
import { useUserStore } from '../user'

interface PayPriceState {
  priceData: PaymentCalculatePriceQuery['paymentCalculatePrice'] | null

  //* DISCOUNTS
  useDiscounts: boolean

  //* ADDITIONAL SERVICES
  additionalServicesIds: string[]
}

export const usePayPriceStore = defineStore({
  id: 'PayPrice',

  state: (): PayPriceState => ({
    priceData: null,

     //* DISCOUNTS
    useDiscounts: true,

    //* ADDITIONAL SERVICES
    additionalServicesIds: []
  }),

  getters: {
    calculatePriceData(): PaymentCalculatePriceInput | null {
      const payStore = usePayStore()

      if (!payStore.readyForPayment) return null
      if (!payStore.purchaseData) return null
      if (!payStore.purchaseData.productType) return null

      const productType = payStore.purchaseData.productType

      const payCouponStore = usePayCouponStore()
      const paySubscriptionProductGiftCardStore = usePaySubscriptionProductGiftCardStore()
      const payUserProductSubscriptionPaymentStore = usePayUserProductSubscriptionPaymentStore()
      const pointsStore = usePayPointsStore()
      const userStore = useUserStore()
      
      return {
        productType: productType,
        couponSlug: payCouponStore.selectedCouponSlug,
        pointProduct: productType === ProductType.PointProduct ? payStore.purchaseData.pointProductId : null,
        subscriptionProduct: productType === ProductType.SubscriptionProduct ? payStore.purchaseData.subscriptionProductId : null,
        resourceBookingInfo: productType === ProductType.ResourceBooking? {
          resource: payStore.purchaseData?.resourceBooking?.resourceId!,
          startDate: payStore.purchaseData?.resourceBooking?.startDate,
          endDate: payStore.purchaseData?.resourceBooking?.endDate,
          attendees: payStore.purchaseData?.resourceBooking?.attendees,
          internalNotes: payStore.purchaseData?.resourceBooking?.internalNotes,
        } : null,
        workshopBookingInfo: productType === ProductType.Workshop? {
          workshop: payStore.purchaseData?.workshopBooking?.workshopId!,
          userBookingNote: payStore.purchaseData.workshopBooking?.userBookingNote
        } : null,
        restPointsPointAccount: pointsStore.restPointAccountId,
        userProductSubscriptionId: payUserProductSubscriptionPaymentStore.useUserProductSubscriptionId,
        additionalServicesIds: this.additionalServicesIds,
        useDiscounts: this.useDiscounts,
        subscriptionProductGiftCardCode: paySubscriptionProductGiftCardStore.selectedGiftCardCode,
        resourceBookingAttendeeBuyOut: payStore.purchaseData.resourceBookingAttendeeBuyOutInput,
        companyId: userStore.user?.companyId
      }
    },
  
    //* DISCOUNTS
    discountPercentage: state => {
      if (!state.priceData) return 0
      return state.priceData
        ? state.priceData.amountWithoutCoupon !== state.priceData.amountToPay
          ? Math.round((state.priceData.amountWithoutCoupon - state.priceData.amountToPay) / state.priceData.amountWithoutCoupon * 100)
          : 0
        : 0
    },
    
    isUsingDiscounts: state => state.priceData?.amountWithoutCoupon !== state.priceData?.amountToPay,
  },

  actions: {
    async updatePriceAdditionalServices(addedServices: string[]) {
      this.additionalServicesIds = addedServices
    },
    async initCalcuatePrice(source: string, doPaymentTypes = true) {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('initCalcuatePrice', true, {
        source,
        doPaymentTypes
      })

      await this.calculatePrice({
        source: 'initCalcuatePrice'
      })

      const payCouponStore = usePayCouponStore()
      const payUserProductSubscriptionPaymentStore = usePayUserProductSubscriptionPaymentStore()

      if (await payUserProductSubscriptionPaymentStore.getCanDoUserSubscriptionPayment('initCalcuatePrice')) {
        payLoadingStore.set('initCalcuatePrice', false, {
          returnedByGetCanDoUserSubscriptionPayment: true
        })
        return
      }

      if (await payCouponStore.initCoupons()){
        payLoadingStore.set('initCalcuatePrice', false, {
          returnedByInitCoupons: true
        })
        return
      }

      if (doPaymentTypes) {
        const paymentMethodStore = usePayPaymentMethodStore()
        await paymentMethodStore.getAllowedPaymentTypes('initCalculatePrice')
      }

      payLoadingStore.set('initCalcuatePrice', false)
    },

    async calculatePrice(data: { source: string }) {
      if (!this.calculatePriceData) throw new Error(i18n.t('common.staticTemp.errorMessageCalcPrice') as string)
      
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set(`calculatePrice`, true, {
        ...data,
        calculatePriceData: this.calculatePriceData
      })

      const { data: calculatedPrice } = await apolloClient.query<PaymentCalculatePriceQuery, PaymentCalculatePriceQueryVariables>({
        query: PaymentCalculatePrice,
        variables: { data: {
          ...this.calculatePriceData,
        }},
        fetchPolicy: 'network-only'
      })
      
      payLoadingStore.set(`calculatePrice`, false, calculatedPrice.paymentCalculatePrice)

      this.priceData = calculatedPrice.paymentCalculatePrice
    },

    resetPrice() {
      this.priceData = null
      this.useDiscounts = true
    },

    async setUseDiscounts(newState: boolean) {
      this.useDiscounts = newState
      await this.initCalcuatePrice('setAllowUseDiscounts')
    }
  }
})
