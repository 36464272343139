<template lang="pug">
b-modal(
  hide-header
  v-model="showUpdateAppModal"
  ref="add-user-to-group-modal"
  title-tag="h4"
  centered
  hide-footer
  size="md")
  h3 {{ $t('components.shared.appVersionModal.title') }}
  p.text-muted {{ $t('components.shared.appVersionModal.text') }}

  b-button(
    block
    @click="openAppInAppStore()"
    variant="primary") {{ $t('components.shared.appVersionModal.openAppStore') }}

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import {  } from '@/graphql'
import { Capacitor } from '@capacitor/core'
import { getStripePaymentElementsCompatibility } from '@/utils/getStripePaymentElementsCompatibility'

@Component({
  components: {

  },
  apollo: {

  }
})
export default class CompanyUsersUserView extends Vue {

  showUpdateAppModal: boolean = false

  created() {
    this.checkForRightVersion()
  }

  async openUrl(openUrl: string) {
    const win = window.open(openUrl, '_blank')
		win!.focus()
  }

	openAppInAppStore() {
		const platform = Capacitor.getPlatform()
		if (platform === 'android') this.openUrl('https://play.google.com/store/apps/details?id=dk.bookli.app')
		else if (platform === 'ios') this.openUrl('https://apps.apple.com/dk/app/bookli/id1551810263')
	}

  async checkForRightVersion() {
    this.showUpdateAppModal = !(await getStripePaymentElementsCompatibility())
	}
}
</script>

<style lang="sass" scoped>

</style>