<template>
    <div class="px-3 py-3 border-top">
        <div>
            <b-button 
                class="d-flex align-items-center justify-content-center" 
                block 
                size="lg"
                variant="primary"
                :disabled="payStore.paymentIsStarted || !payStore.isInitialized"
                @click="proceedToPayment">
                <span >
                    {{ paymentText }}
                </span> 
            </b-button>
        </div>
</div>
</template>
<script lang="ts">
import Vue, { computed } from 'vue'
import { PaymentTokenPaymentType, ProductType } from '@/graphql'
import { usePayStore } from '@/store/modules/pay/pay'
import { usePayPaymentMethodStore } from '@/store/modules/pay/paymentMethod'
import { usePayPointsStore } from '@/store/modules/pay/points'
import { i18n } from '@/locales/setupI18n'

export default Vue.extend({
    setup() {
        const payStore = usePayStore()
        const payPointsStore = usePayPointsStore()
        const paymentMethodStore = usePayPaymentMethodStore()

        const proceedToPayment = async (): Promise<void> => {
            await payStore.startPayment()
	    }

        const paymentText = computed(() => {
            switch(paymentMethodStore.selectedPaymentType) {
                case PaymentTokenPaymentType.Free:
                    return i18n.t('components.app.pay.index.payWith') + ' ' + i18n.t('components.app.pay.index.paymentOptions.free')
                case PaymentTokenPaymentType.UserProductSubscription:
                    return i18n.t('components.app.pay.index.payWith') + ' ' + i18n.t('components.app.pay.index.paymentOptions.subscription')
                case PaymentTokenPaymentType.Points:
                    return i18n.t('components.app.pay.index.payWith') + ' ' + i18n.t('components.app.pay.index.paymentOptions.points')
                case PaymentTokenPaymentType.Stripe:
                    if (payStore.purchaseData?.productType === ProductType.SubscriptionProduct) {
                        return i18n.t('components.app.subscriptionProduct.createSubscription.activateMembership')
                    } else {
                        return i18n.t('components.app.pay.index.proceedToPayment')
                    }
                default:
                    return i18n.t('components.app.pay.index.proceedToPayment')
            }
        })

        return {
            payStore,
            payPointsStore,
            paymentMethodStore,
            proceedToPayment,
            paymentText
        }
    }
})
</script>
<style lang="scss" scoped>
</style>