<template lang="pug">
div
	div(v-if="!paySubscriptionProductGiftCardStore.selectedGiftCardCode")
		b-link.m-0.text-primary.d-flex.align-content-center(
			:disabled="payStore.paymentIsStarted"
			@click="paySubscriptionProductGiftCardStore.showGiftCardModal = true")
			i.fe.fe-plus-circle.mr-2
			span {{ $t('components.app.pay.subscriptionProductGiftCard.title') }}

	div(v-else class="flex flex-col items-end")
		p.m-0.mb-1.text-muted {{ $t('components.app.pay.subscriptionProductGiftCard.firstPaymentDate') }}:
		h4.m-0 {{ paySubscriptionProductGiftCardStore.newSubscriptionStartDateFormated }}


	b-modal(
		:title="$t('components.app.pay.subscriptionProductGiftCard.addModal.title')"
		ref="add-subscription-productgift-card-modal"
		v-model="paySubscriptionProductGiftCardStore.showGiftCardModal"
		title-tag="h4"
		centered
		hide-footer
		size="sm")

		b-input(
			:placeholder="$t('components.app.pay.subscriptionProductGiftCard.addModal.inputPlaceholder')"
			v-model="paySubscriptionProductGiftCardStore.giftCardCodeInput"
			:disabled="paySubscriptionProductGiftCardStore.validateLoading")

		b-button.mt-4(
			:disabled="(paySubscriptionProductGiftCardStore.giftCardCodeInput && paySubscriptionProductGiftCardStore.giftCardCodeInput.length < 1) || paySubscriptionProductGiftCardStore.validateLoading"
			@click="paySubscriptionProductGiftCardStore.setSubscriptionProductGiftCardCode(paySubscriptionProductGiftCardStore.giftCardCodeInput)"
			block
			variant="primary"
			size="lg")
			span(v-if="paySubscriptionProductGiftCardStore.validateLoading")
				b-spinner.button-loading(small type="grow")
				span {{ $t('components.app.pay.subscriptionProductGiftCard.addModal.submitLoading') }}
			span(v-else) {{ $t('components.app.pay.subscriptionProductGiftCard.addModal.submit') }}

		b-link.d-block.m-0.mt-3.text-danger.text-center(@click="paySubscriptionProductGiftCardStore.showGiftCardModal = false") {{ $t('components.app.pay.coupon.addCouponModal.cancel') }}

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { usePayStore } from '@/store/modules/pay/pay'
import { usePaySubscriptionProductGiftCardStore } from '@/store/modules/pay/subscriptionProductGiftCard'

@Component({
	components: {

	},
	apollo: {

	}
})
export default class CompanyUsersUserView extends Vue {
	paySubscriptionProductGiftCardStore = usePaySubscriptionProductGiftCardStore()
	payStore = usePayStore()

}
</script>
<style lang="sass" scoped>

</style>
